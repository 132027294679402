export const CLIENT_TYPE = 2;
export const CLIENT_SECRET = '';
export const APP_VERSION = '5.0.191.1';
export const COMMIT = '917ebfaf82ad3447ab183e846775a92158d8feee';
export const BRANCH = 'proton-vpn-settings@5.0.191.1';
export const DATE_VERSION = 'Wed, 02 Apr 2025 14:27:10 GMT';
export const APP_NAME = 'proton-vpn-settings';
export const API_URL =  '/api';
export const SSO_URL = '';
export const LOCALES = {"en_US":"English","de_DE":"Deutsch","fr_FR":"Français","nl_NL":"Nederlands","es_ES":"Español (España)","es_LA":"Español (Latinoamérica)","it_IT":"Italiano","pl_PL":"Polski","pt_BR":"Português (Brasil)","ru_RU":"Русский","tr_TR":"Türkçe","ca_ES":"Català","cs_CZ":"Čeština","da_DK":"Dansk","fi_FI":"Suomi","hr_HR":"Hrvatski","hu_HU":"Magyar","id_ID":"Bahasa (Indonesia)","nb_NO":"Norsk bokmål","pt_PT":"Português (Portugal)","ro_RO":"Română","sk_SK":"Slovenčina","sl_SI":"Slovenščina","sv_SE":"Svenska","el_GR":"Ελληνικά","be_BY":"Беларуская","uk_UA":"Українська","ka_GE":"Ქართული","hi_IN":"हिन्दी (भारत)","ko_KR":"한국어","ja_JP":"日本語","zh_TW":"繁體中文","fa_IR":"فارسی"};
export const VERSION_PATH = '/assets/version.json';
export const SENTRY_DSN = 'https://38dd62570d3b4791a4feca7bae54bd20@sentry/21';
export const SENTRY_DESKTOP_DSN = '';